import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core"
import algoliasearch from "algoliasearch/lite"
import { Button } from "gatsby-theme-material-ui"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import React, { useMemo } from "react"
import { Configure, connectHits, InstantSearch } from "react-instantsearch-dom"
import Layout from "../components/layout"

const fields = [
  { title: "Accno", attrib: "ACCNO" },
  { title: "Title", attrib: "TITLE" },
  { title: "Author", attrib: "AUTHOR" },
  { title: "Publisher", attrib: "Publisher" },
  { title: "City/Town", attrib: "CITY_TOWN" },
  { title: "Province/State", attrib: "PROVINCE_STATE" },
  { title: "Country", attrib: "COUNTRY" },
  { title: "Type", attrib: "TYPE" },
  {
    title: "Year",
    attrib: "YEAR",
    subKey: "START",
    customFunc: attrib =>
      attrib.END ? `${attrib.START} - ${attrib.END}` : attrib.START,
  },
  {
    title: "Language",
    attrib: "Language",
    subKey: "1",
    customFunc: attrib =>
      attrib["1"] +
      (attrib["2"] && `, ${attrib["2"]}`) +
      (attrib["3"] && `, ${attrib["3"]}`),
  },
  { title: "Subject", attrib: "SUBJECT" },
  { title: "Pages", attrib: "PAGES" },
]

const CustomHits = connectHits(({ hits }) =>
  hits.map(hit => (
    <Box py={8}>
      <Box pb={2}>
        <Typography variant="h2" color="primary" style={{ paddingBottom: 8 }}>
          {hit.TITLE}
        </Typography>
        <Typography variant="h3">
          {hit.AUTHOR} ({hit.YEAR.START})
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {fields.map(({ title, attrib, customFunc }) => (
              <TableRow hover>
                <TableCell variant="head" align="right">
                  {title}
                  {": "}
                </TableCell>
                <TableCell style={{ fontWeight: 600 }}>
                  {customFunc ? customFunc(hit[attrib]) : hit[attrib]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box pt={2}>
        <Button to="/search" startIcon={<ArrowBackIcon />}>
          Back to Search
        </Button>
      </Box>
    </Box>
  ))
)

const ItemPage = ({ location }) => {
  const accNo = location.hash.substring(1)

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  return (
    <Layout>
      <Container maxWidth="sm">
        <InstantSearch searchClient={searchClient} indexName={`sarrc_catalog`}>
          <Configure hitsPerPage={1} filters={`ACCNO:${accNo}`} />
          <CustomHits />
        </InstantSearch>
      </Container>
    </Layout>
  )
}

export default ItemPage
